/**
 * This component uses react-jsonschema-form which is used to automatically generate forms from the json schema we provide from our API.
 * react-jsonschema-form uses several UI libraries to render components and it does no support tailwindcss currently, thus we use a shadow-dom
 * to load bootstrap 3 css to load styles for the form
 */

import Form from 'react-jsonschema-form';
import root from 'react-shadow';
import { useGetDynamicPipelineSchema } from '@src/hooks';
import { Button, Loading } from '@src/components';
import { useEffect, useState } from 'react';
import { AppliedModifierOrSelector } from '@src/models';

type DynamicPipelineSchemaFormProps = {
  model: string;
  modifierOrSelector?: AppliedModifierOrSelector;
  onCancel: () => void;
  onSubmit: (data: AppliedModifierOrSelector) => void;
};

export const DynamicPipelineSchemaForm = ({
  model,
  modifierOrSelector,
  onCancel,
  onSubmit
}: DynamicPipelineSchemaFormProps) => {
  const cssHrefs = [
    'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css'
  ];
  const [cssLoaded, setCssLoaded] = useState<boolean[]>([]);

  useEffect(() => {
    setCssLoaded(new Array(cssHrefs.length).fill(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isLoading } = useGetDynamicPipelineSchema(model);
  const isReady = cssLoaded.every(x => x === true) && !isLoading;

  const uiSchema = {
    id: { 'ui:widget': 'hidden' },
    applyTo: data?.properties.applyTo?.default ? { 'ui:widget': 'hidden' } : {},
    kwargs: { 'ui:title': 'Arguments' }
  };

  let initialFormData: { [key: string]: any } = {};
  if (modifierOrSelector) {
    // deep copy to keep old data
    initialFormData = JSON.parse(JSON.stringify(modifierOrSelector));
  } else {
    initialFormData = {
      id: data?.properties.id.const
    };
    if (data?.properties.applyTo?.default) {
      initialFormData['applyTo'] = data?.properties.applyTo.default;
    }
  }

  return (
    <>
      {!isReady && <Loading />}

      <root.div>
        {cssHrefs.map((href, index) => (
          <link
            key={href}
            rel="stylesheet"
            href={href}
            type="text/css"
            onLoad={() => {
              setCssLoaded(prevState => {
                const cssLoadedCopy = [...prevState];
                cssLoadedCopy[index] = true;
                return cssLoadedCopy;
              });
            }}
          />
        ))}

        {isReady && (
          <Form
            className="m-4"
            schema={data}
            uiSchema={uiSchema}
            formData={initialFormData}
            onSubmit={e => onSubmit(e.formData as AppliedModifierOrSelector)}
          >
            <div className="my-4 gap-3 float-right">
              <Button secondary onClick={() => onCancel()}>
                {modifierOrSelector ? 'Close' : 'Back'}
              </Button>
              <Button type="submit" className="ml-3">
                {modifierOrSelector ? 'Update' : 'Add'}
              </Button>
            </div>
          </Form>
        )}
      </root.div>
    </>
  );
};
